import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/ES/layout'

import pic11 from '../../assets/images/pic11.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1> Objetivo del proyecto </h1>
                    </header>
                    <span className="image main"><img src={pic11} alt="" /></span>
                    <p> La temática principal del proyecto
es la promoción de la educación en/a través del deporte, parando especial atención al
desarrollo de habilidades por parte de los entrenadores que trabajan en el ámbito del deporte
adaptado/unificado en general, y de la danza adaptada/unificada en particular.
<br/><br/> Esto está directamente
relacionada con la inclusión social y la igualdad de oportunidades en el deporte; razón por la
cual parte de las actividades a nivel local vas a desarrollarse alrededor de esta temática.

<br/><br/> El objetivo del proyecto es el de promover y apoyar los
instrumentos de aprendizaje mutuo, la investigación interdisciplinar que apunta a un marco
analítico basado en la evidencia, y el intercambio de conocimientos y buenas prácticas en el
ámbito de la danza o deporte adaptada/unificada. Esto se pretende hacer a través de
seminarios y reuniones de grupos de expertos en aspectos relacionados con la danza y la
inclusión social, junto con las correspondientes acciones de difusión y conexión de los
resultados en cuestión en cada uno de los países participantes.</p>
                </div>
            </section>
        </div>

    </Layout>
)

export default Generic